import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectsBox from "../components/ProjectsBox"
import phone from '../images/phone.svg'
import email from '../images/email.svg'

const ContactsPage = () => (
  <Layout>
    <SEO title="Kontaktai" />
    <section className="contacts-page">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-xs-12">
            <h3 className="small-title">Kontaktai</h3>
            <p>Darbo dienomis galite susisiekti Jums patogiausiu būdu. Po darbo valandų ar nedarbo dienomis kreipkitės el. paštu arba užpildydami kontaktų formą. </p>
            <p className="adresas">K. Donelaičio g. 40-2, <br />
              Kaunas 44241, Lietuva
            </p>
            <p className="contact-btn"><a href="tel:+37064233366"><img src={phone} /> +370 642 33366</a></p>
            <p className="contact-btn"><a href="mailto:agne.ileviciene@savasbustas.lt"><img src={email} /> agne.ileviciene@savasbustas.lt</a></p>
          </div>
          <div className="col-md-5 m-auto col-xs-12">
            <h3 className="small-title">Kontaktų forma</h3>
            <p>Į užklausas atsakysiu per artimiausias 24 valandas.</p>

            <form name='kontaktu-forma' className="kontaktu-forma" action='/aciu' data-netlify='true' data-netlify-honeypot='bot-field' method='post' autoComplete="on">
              <div className="form-group">
                <label className="label">Vardas <span>*</span></label>
                <div className="control">
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="kontaktu-forma" />
                  <input className="form-control" name="name" type="text" placeholder="" required></input>
                </div>
              </div>

              <div className="form-group">
                <label className="label">El. paštas</label>
                <div className="control">
                  <input className="form-control" type="email" name="email" placeholder=""></input>
                </div>
              </div>

              <div className="form-group">
                <label className="label">Tel. nr. <span>*</span></label>
                <div className="control">
                  <input className="form-control" type="number" name="number" placeholder="" required></input>
                </div>
              </div>

              <div className="form-group">
                <label className="label">Jūsų žinutė <span>*</span></label>
                <div className="control">
                  <textarea className='form-control' rows='5' name="message" placeholder='' name='message'></textarea>
                  <div data-netlify-recaptcha="true"></div>
                </div>
              </div>

              <button className='btn btn-primary' id='form-submit-btn' type='submit'>Siųsti pranešimą</button>
            </form>

          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default ContactsPage;
